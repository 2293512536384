/** 為了擴展 `FrInstrumentOfSymbol[]` 開發者語義 */
export class _OfFuturesArray extends Array {
    constructor(args) {
        super(...args);
    }
    /** 依賴後端 json 要把 `default` 放在第0位元素 */
    get default() {
        return this.at(0);
    }
}
