export var FrInstrumentType;
(function (FrInstrumentType) {
    FrInstrumentType["\u53F0\u7063\u80A1\u7968"] = "stock";
    FrInstrumentType["\u53F0\u7063\u9078\u64C7\u6B0A"] = "option";
    FrInstrumentType["\u6307\u6578"] = "index";
    FrInstrumentType["\u671F\u8CA8"] = "futures";
    FrInstrumentType["\u672A\u77E5"] = "unknown";
    FrInstrumentType["\u6D77\u5916\u80A1\u7968"] = "os_stock";
})(FrInstrumentType || (FrInstrumentType = {}));
export var FrInstrumentExchange;
(function (FrInstrumentExchange) {
    FrInstrumentExchange["TWSE"] = "TWSE";
    FrInstrumentExchange["TFE"] = "TFE";
    FrInstrumentExchange["binance"] = "binance";
    FrInstrumentExchange["URX"] = "URX";
    FrInstrumentExchange["CBT"] = "CBT";
    FrInstrumentExchange["CME"] = "CME";
    FrInstrumentExchange["SGX"] = "SGX";
    FrInstrumentExchange["HKF"] = "HKF";
    FrInstrumentExchange["IUS"] = "IUS";
    FrInstrumentExchange["NYM"] = "NYM";
    FrInstrumentExchange["CMX"] = "CMX";
    FrInstrumentExchange["NASDAQ"] = "NASDAQ";
    FrInstrumentExchange["NYSE"] = "NYSE";
    FrInstrumentExchange["DJ"] = "DJ";
    FrInstrumentExchange["RUSSEL"] = "RUSSEL";
    FrInstrumentExchange["SP"] = "SP";
    FrInstrumentExchange["CBOE"] = "CBOE";
})(FrInstrumentExchange || (FrInstrumentExchange = {}));
