import { css } from '@emotion/react';
import { Fragment } from 'react';
import { GlobalParentsHeight100 } from '~/pages/heineken_template/_private/GlobalParentsHeight100';
import { component } from '~/utils/component';
/** 就是個純容器，使整個畫畫寬高 100% 之後，內容你自己塞 */
export const Preset_EntirePage = component(function Preset_EntirePage(props) {
    return (<Fragment>
      <GlobalParentsHeight100 />
      <div className={props.className} css={css `
          display: grid;
          width: 100%;
          height: 100%;
        `}>
        {props.children}
      </div>
    </Fragment>);
});
