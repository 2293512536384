export const twAMFutures = ['TXAM'];
export const twPMFutures = ['TXPM'];
export const twAutoSessionFutures = ['TX', 'MTX', 'TE', 'TF'];
export const twIndex = ['TSE', 'TWXI', 'OTCA'];
export const cmeFutures = [
    'YM',
    'NQ',
    'ES',
    'RTY',
    'CL',
    'GC',
    'HG',
    'SI',
    'NG',
    'PL',
    'TN',
    'TU',
    'FV',
    'TY',
    'US',
    'UB',
    'DX',
    'AD',
    'BP',
    'CD',
    'EC',
    'JY',
    'SF',
];
export const cbotFutures = ['BO', 'S', 'W'];
export const cbotC = ['C'];
export const nybotKC = ['KC'];
export const nybotSB = ['SB'];
export const nybotCC = ['CC'];
export const sgxTWN = ['TWN'];
export const sgxCN = ['CN', 'FCH'];
export const sgxNK = ['NK'];
export const sgxIN = ['IN'];
export const hkfFutures = ['HCEI', 'HSI'];
export const hkfMTW = ['MTW'];
export const eurex = [
    'FDX',
    'FDXM',
    'FGBS',
    'FGBM',
    'FGBL',
    'FGBX',
    'FOAT',
    'STXE',
    'FSXE',
    'FMCH',
    'FSTB',
    'FVS',
    'FXXP',
];
export const usStock = [
    'TSM',
    'AAPL',
    'CSCO',
    'INTC',
    'META',
    'MSFT',
    'TSLA',
    'BIDU',
    'AMZN',
    'COST',
    'EBAY',
    'GOOGL',
    'NVDA',
    'NFLX',
    'BA',
    'UNH',
    'XOM',
    'JNJ',
    'JPM',
    'V',
];
export const foreign = [
    'XAUUSD',
    'USDTWD',
    'EURUSD',
    'USDJPY',
    'GBPUSD',
    'AUDUSD',
    'USDCAD',
    'USDCHF',
    'USDCNY',
    'USDHKD',
    'NZDUSD',
    'XAGUSD',
    //-------
    'HK50',
    'JP225',
    'DE40',
    'UK100',
    'US30',
    'CN50',
    'XTIUSD',
    'USTECH100',
    'US500',
];
