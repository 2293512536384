import { memo } from 'react-tracked';
import { expectType } from '~/utils/tsd';
import { __TEST__ } from '~/utils/__TEST__';
/**
 * - 就把它當作 {@link memo} 來看即可
 * - 使用 `npm:react-tracked` 的 `memo(Component)` ；而非 `npm:react`；主要用來避開 `npm:valtio` 的 bug， 詳見
 *   「https://valtio.pmnd.rs/docs/how-tos/some-gotchas」 的篇章 「Using React.memo with object props may
 *   result in unexpected behavior」 。原文：
 *
 * ```md
 *     The snap variable returned by useSnapshot(state) is tracked for render optimization.
 *     If you pass the snap or some objects in snap to a component with React.memo,
 *     it may not work as expected because React.memo can skip touching object properties.
 *
 *     Side note: react-tracked has a special memo exported as a workaround.
 *
 *     We have some options:
 *
 *     a. Do not use React.memo.
 *
 *     b. Do not pass objects to components with React.memo (pass primitive values instead).
 *
 *     c. Pass in the proxy of that element, and then useSnapshot on that proxy.
 * ```
 *
 * - 用來避開懶得寫 `eslint(react/display-name)` 用的；但依然可透過第二參數傳遞更有意義的 displayName。
 *
 * @example
 *   //
 *   // 主要用於「封裝組件到某個 jsClass 時使用」
 *   // 醬可以省去「在 constructor 裡面寫 this.Component.displayName = 'XXX'」的步驟
 *   //
 *   class DatafeedStatusModule {
 *     store: {
 *       lastUpdatedAt: Dayjs
 *     }
 *
 *     constructor() {
 *       this.store = proxy({
 *         lastUpdatedAt: dayAPI('1970/01/01 00:00:00+00'),
 *       })
 *     }
 *
 *     analyzeKBars(kbars: ChartTypes.DatafeedBar[]) {
 *       const latestBar = maxBy(kbars, 'time')
 *
 *       if (!latestBar?.time) return
 *
 *       this.store.lastUpdatedAt =
 *         // `latestBar.time` 因為它沒有精確到秒，所以這邊以「client 最後接值的當前時間」為主
 *         dayAPI()
 *     }
 *
 *     LatestUpdated = ref(
 *       component(
 *         props => {
 *           const state = useSnapshot(this.store)
 *
 *           return (
 *             <div>圖表K棒最後更新於 {state.lastUpdatedAt.format('YYYY/MM/DD HH:mm:ss')}</div>
 *           )
 *         },
 *         {
 *           displayName: `${DatafeedStatusModule.name}.圖表K棒最後更新時間`,
 *         },
 *       ),
 *     )
 *   }
 */
export function component(Component, options) {
    const component_ = memo(Component);
    if (options?.displayName) {
        component_.displayName = options?.displayName;
        component_.whyDidYouRender = options.why;
    }
    return component_;
}
/* eslint-disable @typescript-eslint/ban-ts-comment */
/* istanbul ignore next */
if (__TEST__) {
    // 場景：自行給 typing
    const MyCompon1 = component(props => {
        expectType(props.foo);
        expectType(props.bar);
        return <div className={props.className}>{props.children}</div>;
    });
    // 場景：若 typing 都不給，則預設有 className 和 children
    const MyCompon2 = component(props => {
        return <div className={props.className}>{props.children}</div>;
    });
    // 場景：客製 displayName
    const MyCompon3 = component(props => {
        return <div className={props.className}>{props.children}</div>;
    }, {
        displayName: '我的組件',
    });
}
/* eslint-enable @typescript-eslint/ban-ts-comment */
