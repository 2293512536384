import { isString } from 'lodash';
/** 幫把傳入的 arguments 改大寫 */
export function ArgUpperCase(options) {
    return function methodDecorator(instance, methodName, descriptor) {
        const fn = descriptor.value;
        descriptor.value = function (...args) {
            if (isString(args[0])) {
                args[0] = args[0].toUpperCase();
            }
            if (isString(args[1]) && options?.arg2) {
                args[1] = args[1].toUpperCase();
            }
            if (isString(args[2]) && options?.arg3) {
                args[2] = args[2].toUpperCase();
            }
            if (isString(args[3]) && options?.arg4) {
                args[3] = args[3].toUpperCase();
            }
            return fn.apply(this, args);
        };
    };
}
